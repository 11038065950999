import Vue from 'vue';
import Vuex from 'vuex';
import Pusher from 'pusher-js'; // import Pusher
import vuexI18n from 'vuex-i18n';
import sortBy from 'lodash/sortBy';

import ratingModule from './modules/rating';
import cartModule from './modules/cart';
import customizationModule from './modules/customization';
import personnelModule from './modules/personnel';
import payGoModule from './modules/payGo';
import userModule from './modules/user';
import onboardingModule from './modules/onboarding';
import integrationsModule from './modules/integrations';
import marketingModule from './modules/marketing';
import foodCourtModule from './modules/foodCourt';
import invoiceModule from './modules/invoice';
import tipsModule from './modules/tips';
import splashScreenModule from './modules/splashScreen';
import paymentHistoryModule from './modules/paymentHistory';
import inPersonPaymentModule from './modules/inPersonPayment';
import worldLineModule from './modules/worldLine';
import donationsModule from './modules/donations';
import printerModule from './modules/printer';

// started writing a new module for orders, just new stuff for now
import ordersModule from './modules/orders';

import { getAuth } from 'firebase/auth';

import formatPriceWithCurrency from '../helpers/formatPriceWithCurrency';
import { PROVIDERS } from '../utils/constants';
import { compact } from 'lodash';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    fetchingMenuStatus: 'idle', // idle, pending
    restaurant: {
      id: null,
      name: null,
      description: null,
      cover: null,
      address: null,
      phone: null,
      mainLanguage: null,
      languages: [],
      loyaltyPricingEnabled: false,
      loyaltyPricingText: null,
      locale: null,
      currency: null,
      functionalities: [],
      quickAccessMenu: [],
      table: null,
      orderStatus: null,
      orderFlow: null,
      type: 'restaurant',
      additional_order_fields_enabled: false,
      additional_order_fields_text: null,
      additional_order_fields: [],
      loyalty_programs: [],
      rush_mode_wait_minutes: null,
      menuFilters: [],
      popups: [],
      googlePlaceId: null,
      food_court_hash: null,
      select_screen_buttons: [],
      socialLinks: [],
      courses: [],
    },
    payment_providers: [],
    categories: [],
    subcategories: [],
    items: [],
    specialDealItems: [],
    moreInformationOpen: false,
    searchOpen: false,
    languageSelectorOpen: false,
    search: '',
    activeCategoryTab: 0,
    categorySliderChanging: false,
    categoryDropdownOpen: false,
    session: false,
    session_mode: 'scan',
    analyticsClickedItems: [],
    analyticsViewedItems: [],
    openedMenuItem: false,
    openedOrder: false,
    subscribedToPusher: false,
    table: [],
    currency: {},
    locale: {},
    orders: [],
    toastOpened: false,
    toastData: {
      title: null,
      description: null,
      action: () => {},
      isError: false,
      doNotClose: false,
    },
    selectedLanguage: false,
    phoneNumber: null,
    rating: {},
    activeMenuFilters: [],
  },
  mutations: {
    TOGGLE_MORE_INFORMATION(state) {
      state.moreInformationOpen = !state.moreInformationOpen;
    },
    OPEN_SEARCH(state) {
      state.searchOpen = true;
    },
    CLOSE_SEARCH(state) {
      state.searchOpen = false;
      state.search = '';
    },
    OPEN_LANGUAGE_SELECTOR(state) {
      state.languageSelectorOpen = true;
    },
    CLOSE_LANGUAGE_SELECTOR(state) {
      state.languageSelectorOpen = false;
    },
    SAVE_MENU(state, categories) {
      state.categories = categories.map(category => {
        return {
          id: category.id,
          type: category.type,
          hash: category.hash,
          name: category.name,
          description: category.description,
          age_restricted: category.age_restricted,
          availability: {
            enabled: category.availability_rules_enabled,
            type: category.availability_rules_type,
            isAvailable: category.is_currently_available,
          },
          banner: category.banner,
        };
      });

      let items = [];
      let specialDealItems = [];
      let subcategories = [];

      categories.forEach(category => {
        category.items.forEach(item => {
          items.push({
            category: category.name,
            categoryHash: category.hash,
            ...item,
          });

          if (item.is_special_deal) {
            specialDealItems.push(item);
          }

          if (item.subcategory) {
            if (
              subcategories.filter(subcategory => {
                return subcategory.name === item.subcategory.name;
              }).length === 0
            ) {
              subcategories.push({
                category: category.name,
                ...item.subcategory,
              });
            }
          }
        });
      });

      state.items = items;
      state.specialDealItems = specialDealItems;
      state.subcategories = subcategories;
    },
    SAVE_RESTAURANT(state, restaurant) {
      state.restaurant.id = restaurant.restaurant.id;
      state.restaurant.name = restaurant.restaurant.name;
      state.restaurant.description = restaurant.restaurant.description;
      state.restaurant.cover = restaurant.restaurant.cover;
      state.restaurant.splashCover = restaurant.restaurant.splash_cover;
      state.restaurant.logo = restaurant.restaurant.logo;
      state.restaurant.facebookLink = restaurant.restaurant.facebook_link;
      state.restaurant.instagramLink = restaurant.restaurant.instagram_link;
      state.restaurant.websiteLink = restaurant.restaurant.website_link;
      state.restaurant.address = restaurant.restaurant.address;
      state.restaurant.phone = restaurant.restaurant.phone;
      state.restaurant.mainLanguage = restaurant.restaurant.main_language;
      state.restaurant.languages = restaurant.restaurant.languages;
      state.restaurant.loyaltyPricingEnabled =
        restaurant.restaurant.loyalty_pricing_enabled;
      state.restaurant.loyaltyPricingText =
        restaurant.restaurant.loyalty_pricing_text;
      state.restaurant.locale = restaurant.restaurant.locale;
      state.restaurant.currency = restaurant.restaurant.currency;
      state.restaurant.functionalities = restaurant.restaurant.functionalities;
      state.currency = restaurant.restaurant.currency;
      state.locale = restaurant.restaurant.locale;
      state.restaurant.quickAccessMenu =
        restaurant.restaurant.quick_access_menu;
      state.restaurant.orderStatus = restaurant.restaurant.order_status;
      state.restaurant.orderFlow = restaurant.restaurant.order_flow;
      state.restaurant.type = restaurant.restaurant.type;
      state.restaurant.loyalty_programs =
        restaurant.restaurant.loyalty_programs;
      state.restaurant.rush_mode_wait_minutes =
        restaurant.restaurant.rush_mode_wait_minutes;
      state.restaurant.select_screen_buttons =
        restaurant.restaurant.select_screen_buttons;
      state.restaurant.socialLinks = restaurant.restaurant.social_links;
      state.restaurant.courses = restaurant.restaurant.courses;
      state.restaurant.pos_integration_enabled =
        restaurant.restaurant.pos_integration_enabled;

      state.restaurant.table = restaurant.table;
      state.table = restaurant.table;
      state.selectedLanguage =
        restaurant.selected_language || restaurant.restaurant.main_language;
      state.phoneNumber = restaurant.phone_number;
      state.restaurant.googlePlaceId = restaurant.restaurant.google_place_id;
      state.restaurant.food_court_hash = restaurant.restaurant.food_court_hash;

      state.restaurant.additional_order_fields_enabled =
        restaurant.restaurant.additional_order_fields_enabled;
      state.restaurant.additional_order_fields_text =
        restaurant.restaurant.additional_order_fields_text;
      state.restaurant.additional_order_fields =
        restaurant.restaurant.additional_order_fields;

      if (restaurant.restaurant?.menu_filters) {
        state.restaurant.menuFilters = restaurant.restaurant.menu_filters;
      }

      state.payment_providers = restaurant.restaurant.payment_providers;
      state.restaurant.popups = restaurant.restaurant.popups;
      state.fetchedLanguages = [];
    },
    INPUT_SEARCH(state, search) {
      state.search = search;
    },
    CHANGE_ACTIVE_CATEGORY_TAB(state, tab) {
      state.activeCategoryTab = tab;
    },
    CATEGORY_SLIDER_CHANGING(state, status) {
      state.categorySliderChanging = status;
    },
    SET_SESSION(state, session) {
      state.session = session;
    },
    SET_SESSION_MODE(state, mode) {
      state.session_mode = mode;
    },
    ADD_TO_ANALYTICS_CLICKED_ITEMS(state, item_id) {
      state.analyticsClickedItems.push(item_id);
    },
    ADD_TO_ANALYTICS_VIEWED_ITEMS(state, item_id) {
      state.analyticsViewedItems.push(item_id);
    },
    SET_SESSION_PHONE_NUMBER(state, phone_number) {
      state.phoneNumber = phone_number;
    },
    CLOSE_CATEGORY_DROPDOWN(state) {
      state.categoryDropdownOpen = false;
    },
    OPEN_CATEGORY_DROPDOWN(state) {
      state.categoryDropdownOpen = true;
    },
    OPEN_MENU_ITEM(state, id) {
      state.openedMenuItem = id;
    },
    CLOSE_MENU_ITEM(state) {
      state.openedMenuItem = false;
    },
    OPEN_ORDER(state, id) {
      state.openedOrder = id;
    },
    CLOSE_ORDER(state) {
      state.openedOrder = false;
    },
    SET_ORDERS(state, orders) {
      state.orders = orders;
    },
    OPEN_TOAST(state) {
      state.toastOpened = true;
    },
    CLOSE_TOAST(state) {
      state.toastOpened = false;
    },
    SET_TOAST_DATA(state, data) {
      state.toastData = {
        title: data.title,
        description: data.description,
        action: data.action,
        isError: !!data.isError || false,
        doNotClose: !!data.doNotClose || false,
      };
    },
    CLEAR_TOAST_DATA(state) {
      state.toastData = {
        title: null,
        description: null,
        action: () => {},
        isError: false,
        doNotClose: false,
      };
    },
    SET_SELECTED_LANGUAGE(state, language) {
      state.selectedLanguage = language;
    },
    SET_ACTIVE_MENU_FILTERS(state, filters) {
      state.activeMenuFilters = filters;
    },
    RESET_RESTAURANT_ID(state) {
      state.restaurant.id = null;
    },
    SET_FETCHING_MENU_STATUS(state, status) {
      state.fetchingMenuStatus = status;
    },
  },
  actions: {
    resetRestaurantId(context) {
      context.commit('RESET_RESTAURANT_ID');
    },
    toggleMoreInformation(context) {
      context.commit('TOGGLE_MORE_INFORMATION');
    },
    openSearch(context) {
      context.commit('OPEN_SEARCH');
    },
    closeSearch(context, searchWasUsed = false) {
      if (context.state.search === '' && !searchWasUsed) {
        context.dispatch('sendAnalyticsEvent', {
          event: 'search_closed_without_input',
        });
      } else {
        context.dispatch('sendAnalyticsEvent', {
          event: 'search_closed',
        });
      }

      context.commit('CLOSE_SEARCH');
    },
    saveMenu(context, menu) {
      context.commit('SAVE_MENU', menu);
    },
    saveRestaurant(context, restaurant) {
      context.commit('SAVE_RESTAURANT', restaurant);
      context.dispatch(
        'saveCustomizations',
        restaurant.restaurant.customizations
      );
    },
    inputSearch(context, search) {
      context.commit('INPUT_SEARCH', search);
    },
    openLanguageSelector(context) {
      context.commit('OPEN_LANGUAGE_SELECTOR');
    },
    closeLanguageSelector(context) {
      context.dispatch('sendAnalyticsEvent', {
        event: 'language_selector_closed',
      });
      context.commit('CLOSE_LANGUAGE_SELECTOR');
    },
    changeActiveCategoryTab(context, tab) {
      context.commit('CHANGE_ACTIVE_CATEGORY_TAB', tab);
    },
    categorySliderChanging(context, status) {
      context.commit('CATEGORY_SLIDER_CHANGING', status);
    },
    setSession(context, session) {
      context.commit('SET_SESSION', session);
    },
    setSessionMode(context, mode) {
      context.commit('SET_SESSION_MODE', mode);
    },
    addToAnalyticsClickedItem(context, itemId) {
      context.commit('ADD_TO_ANALYTICS_CLICKED_ITEMS', itemId);
    },
    addToAnalyticsViewedItem(context, itemId) {
      context.commit('ADD_TO_ANALYTICS_VIEWED_ITEMS', itemId);
    },
    setSessionPhoneNumber(context, phoneNumber) {
      context.commit('SET_SESSION_PHONE_NUMBER', phoneNumber);
    },
    closeCategoryDropdown(context) {
      if (context.state.categoryDropdownOpen) {
        context.dispatch('sendAnalyticsEvent', {
          event: 'category_dropdown_closed',
        });
      }
      context.commit('CLOSE_CATEGORY_DROPDOWN');
    },
    openCategoryDropdown(context) {
      context.commit('OPEN_CATEGORY_DROPDOWN');
    },
    openMenuItem(context, id) {
      context.commit('OPEN_MENU_ITEM', id);
    },
    closeMenuItem(context) {
      context.dispatch('sendAnalyticsEvent', {
        event: 'menu_item_sheet_closed',
      });
      context.commit('CLOSE_MENU_ITEM');
    },
    setOrders(context, orders) {
      context.commit('SET_ORDERS', orders);
    },
    openOrder(context, order_id) {
      context.commit('OPEN_ORDER', order_id);
    },
    closeOrder(context) {
      context.commit('CLOSE_ORDER');
    },
    openToast(context) {
      context.commit('OPEN_TOAST');
    },
    closeToast(context) {
      context.commit('CLOSE_TOAST');
    },
    setToastData(context, data) {
      context.commit('SET_TOAST_DATA', data);
    },
    clearToastData(context) {
      context.commit('CLEAR_TOAST_DATA');
    },
    setSelectedLanguage(context, language) {
      context.commit('SET_SELECTED_LANGUAGE', language);
    },
    setActiveMenuFilters(context, filters) {
      context.commit('SET_ACTIVE_MENU_FILTERS', filters);
    },
    async fetchMenuData(
      context,
      { session, force = false, silent = false } = {}
    ) {
      const menuAlreadyFetched = context.state.categories.length > 0;
      const fetchingMenu = context.getters.fetchingMenu;

      if (fetchingMenu) {
        return;
      }

      if (!menuAlreadyFetched || force) {
        try {
          if (!silent) {
            context.commit('SET_FETCHING_MENU_STATUS', 'pending');
          }
          const menu = await Vue.axios.get(`/get-menu-categories/${session}`);
          const categories = menu.data.data;
          await context.dispatch('saveMenu', categories);
          context.commit('SET_FETCHING_MENU_STATUS', 'idle');
        } catch (error) {
          console.error(error);
          context.commit('SET_FETCHING_MENU_STATUS', 'idle');
        }
      }
    },
    async fetchSessionData(context, { session, force = false } = {}) {
      // Check if we already have the session data
      if (context.state.restaurant.id === null) {
        const sessionData = context.getters.getSessionCookie;
        if ((!sessionData || sessionData?.session !== session) && !force) {
          try {
            (await Vue.axios.get(`/check-same-device/${session}`)).data.data;
          } catch (error) {
            if (error?.response?.data?.message === 'Not the same device') {
              // Redirect to new session
              if (error.response.data.table_hash !== null) {
                window.location = `${process.env.VUE_APP_BASE_URL}/${error.response.data.table_hash}`;
                return;
              }
            } else {
              throw error;
            }
          }
        }

        const sessionDataResponse = await Vue.axios.get(
          `/get-session-data/${session}`
        );
        await context.dispatch('fetchMenuData', { session, force }); // await is important here
        const restaurantData = sessionDataResponse.data.data;

        await context.dispatch('setSession', session);
        await context.dispatch('saveRestaurant', restaurantData);

        const language = restaurantData.restaurant.main_language;

        if (language !== 'lt') {
          const translations = (await import(`../i18n/${language}.js`)).default;
          Vue.i18n.add(language, translations);
          Vue.i18n.set(language);
        }

        getAuth().onAuthStateChanged(user => {
          if (user) {
            context.dispatch('setUser', user);
          } else {
            context.dispatch('clearUser');
          }
        });

        // Initialize GTM data
        if (Vue.gtm) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            restaurant_id: context.state.restaurant.id,
            restaurant_name: context.state.restaurant.name,
            table_id: context.state.table?.id,
            table_name: context.state.table?.name,
          });
          Vue.gtm?.enable(true);
        }

        // Save session data to cookie
        context.dispatch('saveSessionCookie', session);

        context.dispatch('setGlobalCssVariables');

        if (
          context.state.restaurant.languages.includes(
            context.state.selectedLanguage
          ) ||
          context.state.restaurant.mainLanguage ===
            context.state.selectedLanguage
        ) {
          const translations = (
            await import(`../i18n/${context.state.selectedLanguage}.js`)
          ).default;
          Vue.i18n.add(context.state.selectedLanguage, translations);
          if (
            context.state.restaurant.mainLanguage !==
            context.state.selectedLanguage
          ) {
            context.dispatch(
              'fetchTranslationsForLanguage',
              context.state.selectedLanguage
            );
          }
          Vue.i18n.set(context.state.selectedLanguage);
        }

        const isRushMode = context.getters.restaurantIsInRushMode;
        const rush_mode_wait_minutes =
          context.getters.restaurantRushModeWaitMinutes;

        if (isRushMode) {
          context.dispatch('showRushModeToast', { rush_mode_wait_minutes });
        }

        // ASG INTEGRATION
        context.dispatch('sendStripeIntegrationDetails');
        context.dispatch('subscribeToExternalWallets');
      }
    },
    async fetchTranslationsForLanguage(context, languageCode) {
      if (
        context.state.restaurant.languages.includes(languageCode) ||
        context.state.restaurant.mainLanguage === languageCode
      ) {
        await Vue.axios
          .get(`/translations/${context.state.restaurant.id}/${languageCode}/`)
          .then(response => {
            Vue.i18n.add(languageCode, response.data);
          });
        context.state.fetchedLanguages.push(languageCode);
      }
    },
    async fetchOrders(context) {
      if (
        !context.getters.functionalityOrderEnabled &&
        !context.getters.functionalityPayGoEnabled
      ) {
        return;
      }

      // THIS is not ideal, but we need to fetch food court orders also
      if (context.getters.getFoodCourtId) {
        context.dispatch('fetchFoodCourtOrders');
      }

      const orders = (
        await Vue.axios.get(`/orders/get-orders/${context.state.session}`)
      ).data.data;

      await context.dispatch('setOrders', orders);
    },
    async subscribeToPusherChannels(context) {
      if (
        (context.getters.functionalityOrderEnabled ||
          context.getters.functionalityPayGoEnabled) &&
        !context.getters.getFoodCourtSession
      ) {
        // console.log(pusher.allChannels());
        // TODO - check if channel is already subscribed in pusher
        if (!context.state.subscribedToPusher) {
          Pusher.logToConsole =
            process.env.VUE_APP_ENVIRONMENT !== 'production';
          var pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
            cluster: 'eu',
          });

          let channel = pusher.subscribe(
            `session_orders_${context.state.session}`
          );

          // Subscribe to these events only if order-flow is FULL
          if (context.getters.orderFlowIsFull) {
            channel.bind('order-seen-by-waiter', async data => {
              await context.dispatch('fetchOrders');

              const order = context.state.orders.find(order => {
                return order.unique_order_number === data.unique_order_number;
              });

              context.dispatch('setToastData', {
                title: Vue.i18n.translate('components.toast.orderSeenTitle'),
                description: Vue.i18n.translate(
                  'components.toast.orderSeenDescription'
                ),
                action: () => {
                  context.dispatch('openOrder', order.unique_order_number);
                },
              });
              if (!context.state.openedOrder) {
                context.dispatch('openToast');
              }
            });
            channel.bind('order-accepted', async data => {
              await context.dispatch('fetchOrders');
              const order = context.state.orders.find(order => {
                return order.unique_order_number === data.unique_order_number;
              });
              context.dispatch('setToastData', {
                title: Vue.i18n.translate(
                  'components.toast.orderAcceptedTitle'
                ),
                description: Vue.i18n.translate(
                  'components.toast.orderAcceptedDescription',
                  {
                    restaurantName: context.state.restaurant.name,
                  }
                ),
                action: () => {
                  context.dispatch('openOrder', order.unique_order_number);
                },
              });
              if (!context.state.openedOrder) {
                context.dispatch('openToast');
              }
            });
            channel.bind('order-item-delivered', async data => {
              await context.dispatch('fetchOrders');
              const order = context.state.orders.find(order => {
                return order.unique_order_number === data.unique_order_number;
              });
              context.dispatch('setToastData', {
                title: Vue.i18n.translate(
                  'components.toast.orderItemDeliveredTitle'
                ),
                description: Vue.i18n.translate(
                  'components.toast.orderItemDeliveredDescription'
                ),
                action: () => {
                  context.dispatch('openOrder', order.unique_order_number);
                },
              });
              if (!context.state.openedOrder) {
                context.dispatch('openToast');
              }
            });
            channel.bind('order-finished', async data => {
              await context.dispatch('fetchOrders');
              const order = context.state.orders.find(order => {
                return order.unique_order_number === data.unique_order_number;
              });
              context.dispatch('setToastData', {
                title: Vue.i18n.translate(
                  'components.toast.orderFinishedTitle'
                ),
                description: Vue.i18n.translate(
                  'components.toast.orderFinishedDescription'
                ),
                action: () => {
                  context.dispatch('openOrder', order.unique_order_number);
                },
              });
              if (!context.state.openedOrder) {
                context.dispatch('openToast');
              }
            });

            channel.bind('order-wait-time-prolonged', async data => {
              await context.dispatch('fetchOrders');

              const order = context.state.orders.find(order => {
                return order.unique_order_number === data.unique_order_number;
              });

              context.dispatch('setToastData', {
                title: Vue.i18n.translate(
                  'components.toast.orderWaitTimeProlongedTitle'
                ),
                description: Vue.i18n.translate(
                  'components.toast.orderWaitTimeProlongedDescription'
                ),
                action: () => {
                  context.dispatch('openOrder', order.unique_order_number);
                },
              });
              if (!context.state.openedOrder) {
                context.dispatch('openToast');
              }
            });
          }

          if (context.getters.functionalityRejectOrderEnabled) {
            channel.bind('order-rejected', async data => {
              await context.dispatch('fetchOrders');
              const order = context.state.orders.find(order => {
                return order.unique_order_number === data.unique_order_number;
              });
              context.dispatch('setToastData', {
                title: Vue.i18n.translate(
                  'components.toast.orderRejectedTitle'
                ),
                description: Vue.i18n.translate(
                  'components.toast.orderRejectedDescription'
                ),
                action: () => {
                  context.dispatch('openOrder', order.unique_order_number);
                },
                isError: true,
                doNotClose: true,
              });
              if (!context.state.openedOrder) {
                context.dispatch('openToast');
              }
            });
          }

          channel.bind('order-updated', async () => {
            const payGoEnabled = context.getters.functionalityPayGoEnabled;
            const ordersEnabled = context.getters.functionalityOrderEnabled;

            if (ordersEnabled) {
              await context.dispatch('fetchOrders');
            } else if (payGoEnabled) {
              await context.dispatch('fetchOrdersFromPOS');
            }
          });

          if (context.getters.functionalityPayGoEnabled) {
            channel.bind('order-items-selected', async () => {
              window.dispatchEvent(new CustomEvent('order-items-selected'));
            });
          }

          channel.bind('order-failed', async data => {
            await context.dispatch('fetchOrders');
            const order = context.state.orders.find(order => {
              return order.unique_order_number === data.unique_order_number;
            });
            context.dispatch('setToastData', {
              title: Vue.i18n.translate('components.toast.orderFailedTitle'),
              description: Vue.i18n.translate(
                'components.toast.orderFailedDescription'
              ),
              action: () => {
                context.dispatch('openOrder', order.unique_order_number);
              },
              isError: true,
              doNotClose: true,
            });
            if (!context.state.openedOrder) {
              context.dispatch('openToast');
            }
          });

          let restaurantChannel = pusher.subscribe(
            `restaurant_${context.state.restaurant.id}`
          );
          restaurantChannel.bind(
            'rush-mode-activated',
            async ({ rush_mode_wait_minutes }) => {
              context.dispatch('showRushModeToast', { rush_mode_wait_minutes });
            }
          );

          context.state.subscribedToPusher = true;
        }
      }
    },
    saveSessionCookie(context, sessionToken) {
      if (context.state.session_mode !== 'preview') {
        var match = document.cookie.match(new RegExp('(^| )session=([^;]+)'));
        if (!match) {
          let date = new Date();
          const sessionDataToSave = JSON.stringify({
            session: sessionToken,
            restaurant_id: context.state.restaurant.id,
            table_id: context.state.table.id,
          });
          // 6 hours
          date.setTime(date.getTime() + 6 * 60 * 60 * 1000);
          const expires = 'expires=' + date.toUTCString();
          document.cookie =
            'session=' + sessionDataToSave + '; ' + expires + '; path=/';
        }
      }
    },
    deleteSessionCookie() {
      document.cookie =
        'session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    },
    sendAnalyticsEvent: function(state, data = {}) {
      if (Vue.gtm) {
        Vue.gtm.trackEvent({
          ...data,
        });
      }
    },
    showRushModeToast(context, { rush_mode_wait_minutes }) {
      context.dispatch('setToastData', {
        title: Vue.i18n.translate('components.toast.rushMode.title'),
        description: Vue.i18n.translate(
          'components.toast.rushMode.description',
          { time: rush_mode_wait_minutes }
        ),
        action: () => {
          context.dispatch('closeToast');
        },
        isError: true,
        doNotClose: true,
      });
      context.dispatch('openToast');
    },
    showToastInstantly(
      context,
      {
        title,
        description,
        isError = false,
        doNotClose = false,
        action = () => {},
      }
    ) {
      context.dispatch('setToastData', {
        title,
        description,
        action: () => {
          action();
          context.dispatch('closeToast');
        },
        isError,
        doNotClose,
      });
      context.dispatch('openToast');
    },
    async checkIfPaymentIntentExpired(_, { paymentUuid } = {}) {
      await Vue.axios.get(`/payments/expiration-check/${paymentUuid}`);
    },
    async forceFetchMenu({ getters, dispatch }) {
      const session = getters.session;
      try {
        await dispatch('fetchMenuData', { session, force: true, silent: true });
      } catch (error) {
        console.error(error);
      }
    },
    async changeLanguage(context, languageCode) {
      const translations = (await import(`../i18n/${languageCode}.js`)).default;

      Vue.i18n.add(languageCode, translations);
      Vue.i18n.set(languageCode);
      context.commit('SET_SELECTED_LANGUAGE', languageCode);
    },
  },
  getters: {
    session: state => {
      return state.session;
    },
    restaurant: state => {
      return state.restaurant;
    },
    restaurantMenuFilters: state => {
      return state.restaurant.menuFilters;
    },
    activeMenuFilters: state => {
      return state.activeMenuFilters;
    },
    hasMenuFilters: state => {
      return state.restaurant?.menuFilters?.length > 0;
    },
    hasActiveMenuFilters: state => {
      return state.activeMenuFilters.length > 0;
    },
    categories(state, getters) {
      const activeFilters = getters.activeMenuFilters;

      let categories = state.categories;

      if (activeFilters.length > 0) {
        categories = state.categories.filter(category => {
          return activeFilters.includes(category.id);
        });
      }

      return categories.filter(category => {
        return (
          state.items.filter(item => {
            return item.categoryHash === category.hash;
          }).length > 0 &&
          (!category.availability.enabled ||
            (category.availability.enabled &&
              category.availability.isAvailable) ||
            category.availability.type === 'availability')
        );
      });
    },
    categoriesWithBanners(state, getters) {
      const activeFilters = getters.activeMenuFilters;
      let categories = state.categories;

      if (activeFilters.length > 0) {
        categories = state.categories.filter(category => {
          return activeFilters.includes(category.id);
        });
      }

      return categories.filter(
        category =>
          ((state.items.filter(item => {
            return item.categoryHash === category.hash;
          }).length > 0 ||
            category.type === 'banner') &&
            (!category.availability.enabled ||
              (category.availability.enabled &&
                category.availability.isAvailable) ||
              category.availability.type === 'availability')) ||
          category.description?.length > 0
      );
    },
    categoriesWithoutBanners(state, getters) {
      return getters.categoriesWithBanners.filter(category => {
        return category.type !== 'banner';
      });
    },
    functionalityOrderEnabled: function(state) {
      return (
        state.restaurant.functionalities?.includes('order') &&
        state.session_mode !== 'preview' &&
        state.table?.order_type !== 'disabled' &&
        state.table?.disable_orders !== true
      );
    },
    functionalityPayGoEnabled: function(state) {
      return (
        state.restaurant.functionalities?.includes('pay-go') &&
        state.session_mode !== 'preview' &&
        state.table?.order_type !== 'disabled' &&
        state.table?.disable_pay !== true
      );
    },
    functionalitySkipPaymentEnabled: function(state) {
      return state.restaurant.functionalities?.includes('skip-payment');
    },
    functionalityTipsEnabled: function(state) {
      return state.restaurant.functionalities?.includes('tips');
    },
    functionalityCollectiveTipsEnabled: function(state) {
      return state.restaurant.functionalities?.includes('collective-tips');
    },
    functionalityTipsBeforePayment: function(state) {
      return state.restaurant.functionalities?.includes('tips-before-payment');
    },
    functionalityDiscountCodeEnabled: function(state) {
      return state.restaurant.functionalities?.includes('coupon-code');
    },
    functionalityPushNotificationsEnabled: function(state) {
      return state.restaurant.functionalities?.includes('push-notifications');
    },
    functionalitySMSNotificationsEnabled: function(state) {
      return state.restaurant.functionalities?.includes('sms-notifications');
    },
    functionalityOrderCommentEnabled: function(state) {
      return state.restaurant.functionalities?.includes('order-comment');
    },
    functionalityRejectOrderEnabled: function(state) {
      return state.restaurant.functionalities?.includes('reject-order');
    },
    functionalityShowSelectScreenEnabled: function(state) {
      return state.restaurant.functionalities?.includes('show-select-screen');
    },
    functionalityLoyaltyProgramEnabled: function(state) {
      return state.restaurant.functionalities?.includes('loyalty-program');
    },
    functionalityOrderOnboardingEnabled: function(state) {
      return state.restaurant.functionalities?.includes('onboarding-orders');
    },
    functionalityRequestInvoiceEnabled: function(state) {
      return state.restaurant.functionalities?.includes('request-invoice');
    },
    functionalitySendTipsToPosWaiterEnabled: function(state) {
      return state.restaurant.functionalities?.includes(
        'send-tips-to-pos-waiter'
      );
    },
    functionalityUnifiedSplashScreenEnabled: function(state) {
      return state.restaurant.functionalities?.includes(
        'unified-splash-screen'
      );
    },
    functionalityCallWaiterEnabled: function(state) {
      return state.restaurant.functionalities?.includes('call-waiter');
    },
    ordersTemporarilyDisabled: function(state) {
      return (
        state.restaurant.orderStatus === 'temporarily_disabled' ||
        state.restaurant.orderStatus === null
      );
    },
    restaurantIsInRushMode(state) {
      return state.restaurant.orderStatus === 'rush_mode';
    },
    restaurantRushModeWaitMinutes(state, getters) {
      const isRushMode = getters.restaurantIsInRushMode;
      return isRushMode ? state.restaurant.rush_mode_wait_minutes : null;
    },
    requiresExtraOrderDetails: function(state) {
      return (
        !!state.restaurant.additional_order_fields_enabled &&
        state.restaurant.additional_order_fields.length > 0
      );
    },
    getOrders(state, getters) {
      const foodCourtOrders = getters.getFoodCourtOrders;

      if (foodCourtOrders.length > 0) {
        return foodCourtOrders.map(order => order.order);
      }

      return state.orders;
    },
    ordersCount: function(state) {
      return state.orders.length;
    },
    activeOrdersCount: function(state) {
      return state.orders.filter(order => {
        return order.status !== 'closed';
      }).length;
    },
    completedOrdersCount: function(state) {
      return state.orders.filter(order => {
        return order.status === 'closed';
      }).length;
    },
    getSessionCookie: function() {
      var match = document.cookie.match(new RegExp('(^| )session=([^;]+)'));
      if (match) {
        try {
          const sessionData = JSON.parse(match[2]);
          if (
            sessionData.session &&
            sessionData.restaurant_id &&
            sessionData.table_id
          ) {
            return sessionData;
          }
        } catch (e) {
          return false;
        }
      }
      return false;
    },
    restaurantHasLanguages: function(state) {
      const languages = state.restaurant.languages || [];
      return languages.length > 0;
    },
    quickAccessMenu: function(state) {
      return (
        state.restaurant.quickAccessMenu.filter(item => {
          // Filter out hidden categories
          if (item.type === 'category') {
            const category = state.categories.find(category => {
              return category.id === item.data.category_id;
            });
            return !!category;
          }
          return true;
        }) || []
      );
    },
    getSessionPhoneNumber: function(state) {
      return state.phoneNumber;
    },
    orderFlowIsFull: function(state) {
      return state.restaurant.orderFlow === 'full';
    },
    orderFlowIsWithoutStatusChanges: function(state) {
      return state.restaurant.orderFlow === 'received';
    },
    tableTypeIsPickup: function(state) {
      return state.table?.order_type === 'pickup';
    },
    tableTypeIsSelfService: function(state) {
      return state.table?.order_type === 'self_service';
    },
    additionalOrderFieldsText: function(state) {
      return state.restaurant?.additional_order_fields_text;
    },
    additionalOrderFields: function(state, getters) {
      const additionalOrderFields = state.restaurant?.additional_order_fields;
      const isSelfService = getters.tableTypeIsSelfService;

      if (isSelfService) {
        return additionalOrderFields.filter(field => field.kiosk_enabled);
      }

      return additionalOrderFields.filter(field => field?.qr_enabled);
    },
    paymentProviders(state) {
      return state?.payment_providers || [];
    },
    hasPaymentProviders(_, getters) {
      const paymentProviders = getters.paymentProviders;
      return paymentProviders.length > 0;
    },
    paymentProviderStripeEnabled(_, getters) {
      const paymentProviders = getters.paymentProviders;
      return !!paymentProviders.find(
        ({ provider } = {}) => provider === PROVIDERS.STRIPE
      );
    },
    paymentProviderMontonioEnabled(_, getters) {
      const paymentProviders = getters.paymentProviders;

      return !!paymentProviders.find(
        ({ provider } = {}) => provider === PROVIDERS.MONTONIO
      );
    },
    paymentProviderNeopayEnabled(_, getters) {
      const paymentProviders = getters.paymentProviders;

      return !!paymentProviders.find(
        ({ provider } = {}) => provider === PROVIDERS.NEOPAY
      );
    },
    paymentProviderCashEnabled(_, getters) {
      const paymentProviders = getters.paymentProviders;

      return !!paymentProviders.find(
        ({ provider } = {}) => provider === PROVIDERS.CASH
      );
    },
    paymentProviderRoomBillEnabled(_, getters) {
      const paymentProviders = getters.paymentProviders;

      return !!paymentProviders.find(
        ({ provider } = {}) => provider === PROVIDERS.ROOM_BILL
      );
    },
    paymentProviderNeopayBlikEnabled(_, getters) {
      const paymentProviders = getters.paymentProviders;

      return !!paymentProviders.find(
        ({ provider } = {}) => provider === PROVIDERS.NEOPAY_BLIK
      );
    },
    getPrimaryColor: function() {
      return getComputedStyle(document.documentElement).getPropertyValue(
        '--color-primary'
      );
    },
    getCurrencyCode(state) {
      return state.currency.code;
    },
    getFormattedPrice: state => (amount, decimalPlaces) => {
      return formatPriceWithCurrency(
        amount,
        state.currency.code,
        state.locale.locale_code,
        decimalPlaces
      );
    },
    restaurantLocaleCode: state => {
      return state.restaurant.locale.locale_code;
    },
    restaurantCountryCode: state => {
      return state.restaurant.locale.locale_code.split('-')[1];
    },
    currencySymbol: state => {
      return state.currency.symbol;
    },
    orders(state) {
      return state.orders || [];
    },
    openUnpaidOrders: function(_, getters) {
      const orders = getters.orders;
      return orders.filter(order => {
        return !order?.is_paid && order?.status !== 'closed';
      });
    },
    openUnpaidOrdersCount: function(_, getters) {
      const unpaidOrders = getters.openUnpaidOrders;
      return unpaidOrders.length;
    },
    getFirstOpenOrderId: (_, getters) => {
      const orders = getters.orders.filter(order => {
        return order.status !== 'closed';
      });
      if (orders.length > 0) {
        return orders[0]?.unique_order_number;
      }
      return null;
    },
    getOrderByUniqueOrderNumber: (_, getters) => uniqueOrderNumber => {
      const orderFromFoodCourt = getters.getFoodCourtOrderOrderNumber(
        uniqueOrderNumber
      );

      if (orderFromFoodCourt) {
        return orderFromFoodCourt;
      }

      const orders = getters.orders;
      return orders.find(order => {
        return order?.unique_order_number === uniqueOrderNumber;
      });
    },
    getIsOrderAlreadyPaid: (_, getters) => uniqueOrderNumber => {
      const order = getters.getOrderByUniqueOrderNumber(uniqueOrderNumber);
      return order?.is_paid;
    },
    restaurantLoyaltyProgram: state => (external = false) => {
      const programs = state.restaurant.loyalty_programs || [];

      if (external) {
        const externalPrograms = programs.filter(
          program => program.is_external
        );

        return externalPrograms[0] || null;
      }

      return programs[0] || null;
    },
    restaurantLoyaltyPrograms: state => {
      return state.restaurant.loyalty_programs || [];
    },
    hasMultipleLoyaltyPrograms: (_, getters) => {
      const programs = getters.restaurantLoyaltyPrograms;
      return programs.length > 1;
    },
    loyaltyProgramById: (_, getters) => id => {
      const programs = getters.restaurantLoyaltyPrograms;
      return programs.find(program => `${program.id}` === `${id}`);
    },
    additionalLoyaltyFields: (_, getters) => loyaltyId => {
      const loyaltyProgram = getters.loyaltyProgramById(loyaltyId);
      return loyaltyProgram?.additional_fields || [];
    },
    popup: (_, getters) => {
      const restaurant = getters.restaurant;
      return restaurant.popups?.[0] || null;
    },
    googlePlaceId: (_, getters) => {
      const restaurant = getters.restaurant;
      const tipsPlaceId = getters.tipsRecipientGooglePlaceId;

      return restaurant?.googlePlaceId || tipsPlaceId;
    },
    restaurantFoodCourtHash: (_, getters) => {
      const restaurant = getters.restaurant;
      return restaurant.food_court_hash;
    },
    getCurrentRestaurantId: (_, getters) => {
      const restaurant = getters.restaurant;
      return restaurant.id;
    },
    selectScreenButtons: (_, getters) => {
      const restaurant = getters.restaurant;
      return restaurant.select_screen_buttons;
    },
    selectRestaurantSocialLinks: (_, getters) => {
      const restaurant = getters.restaurant;
      return restaurant.socialLinks;
    },
    courses(_, getters) {
      const restaurant = getters.restaurant;
      const courses = restaurant.courses || [];
      const sorted = sortBy(courses, 'sorting');

      return sorted;
    },
    menuItemById: (state, getters, rootState) => id => {
      const menuItem = rootState.items.find(item => {
        return item.id === id;
      });

      return menuItem;
    },
    fetchingMenu: state => {
      return state.fetchingMenuStatus === 'pending';
    },
    mainLanguage: state => {
      return state.restaurant.mainLanguage;
    },
    posIntegrationEnabled: state => {
      return state.restaurant.pos_integration_enabled;
    },
    restaurantLogo: (_, getters) => {
      return getters.restaurant.logo;
    },
    restaurantCover: (_, getters) => {
      return getters.restaurant.cover;
    },
    restaurantSplashCover: (_, getters) => {
      return getters.restaurant.splashCover;
    },
    restaurantName: (_, getters) => {
      return getters.restaurant.name;
    },
    restaurantDescription: (_, getters) => {
      return getters.restaurant.description;
    },
    restaurantSocialLinks: (_, getters) => {
      const restaurant = getters.restaurant;
      const facebookLink = restaurant?.facebookLink;
      const instagramLink = restaurant?.instagramLink;
      const websiteLink = restaurant?.websiteLink;

      return compact([
        facebookLink ? { name: 'facebook', link: facebookLink } : null,
        instagramLink ? { name: 'instagram', link: instagramLink } : null,
        websiteLink ? { name: 'website', link: websiteLink } : null,
      ]);
    },
  },
  modules: {
    user: userModule,
    rating: ratingModule,
    cart: cartModule,
    customization: customizationModule,
    personnel: personnelModule,
    payGo: payGoModule,
    onboardingModule: onboardingModule,
    integrationsModule: integrationsModule,
    marketing: marketingModule,
    foodCourts: foodCourtModule,
    invoice: invoiceModule,
    tips: tipsModule,
    splashScreen: splashScreenModule,
    ordersModule: ordersModule,
    paymentHistory: paymentHistoryModule,
    donations: donationsModule,
    inPersonPayment: inPersonPaymentModule,
    printer: printerModule,
    worldLine: worldLineModule,
  },
});

Vue.use(vuexI18n.plugin, store);

export default store;

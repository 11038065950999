<template>
  <div class="preview-container">
    <NavHeader
      :title="$t('screens.loyaltyPreview.navTitle')"
      @onBackClick="goToRedirectUrl"
    />
    <LayoutCard v-if="restaurantLoyaltyProgram" larger-container grow>
      <div class="text-center mb-10">
        <LoyaltyCard spin :loyalty-program="restaurantLoyaltyProgram" />
        <h2 class="pt-8">
          {{ $t('screens.loyaltyPreview.title', { loyaltyProgramName }) }}
        </h2>
        <div class="tp-text-body mt-3 grey--text text--darken-2">
          {{ $t('screens.loyaltyPreview.subtitle') }}
        </div>
      </div>
      <div class="mt-auto">
        <div v-for="(cta, key) in ctas" :key="key">
          <Button
            :key="key"
            :dark="key % 2 === 0"
            :gray="key % 2 !== 0"
            @click="cta?.action()"
          >
            {{ cta?.label }}
          </Button>
          <DividerWithText
            v-if="key % 1 === 0 && key !== ctas.length - 1"
            :text="$t('generic.or')"
            class="mb-4 mt-4"
          />
        </div>
      </div>
    </LayoutCard>
  </div>
</template>

<script>
import NavHeader from '@/components/Navigation/NavHeader.vue';
import LayoutCard from '@/components/LayoutCard.vue';
import LoyaltyCard from '@/components/Loyalty/LoyaltyCard.vue';
import Button from '@/components/Button.vue';
import DividerWithText from '@/components/common/DividerWithText.vue';

export default {
  components: {
    NavHeader,
    LoyaltyCard,
    LayoutCard,
    Button,
    DividerWithText,
  },
  computed: {
    session() {
      return this.$store.state.session;
    },
    restaurantLoyaltyProgram() {
      return this.$store.getters.restaurantLoyaltyProgram();
    },
    loyaltyProgramName() {
      return this.restaurantLoyaltyProgram.name;
    },
    hasMultipleLoyaltyPrograms() {
      return this.$store.getters.hasMultipleLoyaltyPrograms;
    },
    ctas() {
      const programs = this.$store.getters.restaurantLoyaltyPrograms;

      const getCtaLabel = isExternal => {
        const programsCount = programs.length;

        if (programsCount === 1) {
          return isExternal
            ? this.$t('screens.loyaltyPreview.ctaAddPhysicalCardLabel')
            : this.$t('screens.loyaltyPreview.ctaAddToGreetWallet');
        }

        return isExternal
          ? this.$t('screens.loyaltyPreview.ctaAddPhysicalCardLabel')
          : this.$t('screens.loyaltyPreview.ctaCreateCardLabel');
      };

      const programComponents = programs.map(program => {
        return {
          label: getCtaLabel(program.is_external),
          dark: program.is_external,
          type: 'button',
          action: () => {
            this.$router.push({
              name: 'EnrollLoyalty',
              params: {
                session: this.session,
                loyaltyProgramId: program.id,
              },
              query: {
                redirect: this.$route.query.redirect,
              },
            });
          },
        };
      });

      return programComponents;
    },
  },
  async mounted() {
    const sessionToken = this.$route.params.session;
    await this.$store.dispatch('fetchSessionData', { session: sessionToken });
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goToRedirectUrl() {
      const redirectUrl = this.$route.query.redirect || 'PlaceOrder';

      this.$router.push({
        name: redirectUrl,
        params: {
          session: this.session,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.preview-container {
  display: flex;
  flex-flow: column;
  min-height: 100svh;
  height: 100%;
}
</style>
